import { parse, format} from "date-fns";
import {dateFormat} from "@/utils/consts";

export class PaymentAggregation {
    createdOn = new Date()
    amount = 0
    systemFee = 0
    systemFeeTax = 0
    transferFee = 0
    transferFeeTax = 0
    paymentType = ""

    static fromJson (obj: any): PaymentAggregation {
        const aggr = new PaymentAggregation()
        aggr.createdOn = parse(obj.created_on, dateFormat, new Date())
        aggr.amount = obj.amount
        aggr.systemFee = obj.system_fee
        aggr.systemFeeTax = obj.system_fee_tax
        aggr.transferFee = obj.transfer_fee
        aggr.transferFeeTax = obj.transfer_fee_tax
        aggr.paymentType = obj.payment_type
        return aggr
    }

    static fromJsonArrToArr (objArr: Array<any>): PaymentAggregation[] {
        const arr = [] as PaymentAggregation[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    get dateString (): string {
        return format(this.createdOn, dateFormat)
    }

    get systemFeeTotal (): number {
        return this.systemFee + this.systemFeeTax
    }

    get transferFeeTotal (): number {
        return this.transferFee + this.transferFeeTax
    }

    // get systemFeeTotalString (): string {
    //     return this.systemFeeTotal.toLocaleString()
    // }
    //
    // get transferFeeTotalString (): string {
    //     return this.transferFeeTotal.toLocaleString()
    // }

    get transferredAmount (): number {
        return this.amount - (this.systemFeeTotal + this.transferFeeTotal)
    }

    // get transferredAmountString (): string {
    //     return this.transferredAmount.toLocaleString()
    // }

    get vForKey (): string {
        return `payment_aggregation__${this.dateString}__${this.transferredAmount.toLocaleString()}`
    }
}