
import {computed, defineComponent, nextTick, ref, watch} from "vue";
import {authUpdateOverlayMode, authUpdateOverlayStateStore} from "@/stores/auth-update-overlay-state-store";
import { FontSizes } from "@/utils/font-sizes";
import PasswordInput from "@/components/PasswordInput.vue";
import {authStore} from "@/stores/auth-store";
import {logoutMessages, subPaths} from "@/utils/consts";
import {useRouter} from "vue-router";
import {backendApi} from "@/utils/backend-api";
import {toastStore} from "@/stores/toast-store";
import {Auth, certMailStatuses} from "@/data-models/auth";

export default defineComponent({
    name: "AuthUpdateOverlay",
    components: {
        PasswordInput,
    },
    setup() {

        const password1 = ref<string>(""),
                password2 = ref<string>(""),
                email = ref<string>(""),
                pwWarningMessage = ref<string>(""),
                canClickUpdatePW = ref<boolean>(false),
                mailSentOnce  = ref<boolean>(false),
                showPWFormOverlay = ref<boolean>(false),
                showMailFormOverlay = ref<boolean>(false)

        const cancelText = computed<string>((): string => {
            // このページで認証情報が更新され、確認が通ると文言が変わる。
            return authUpdateOverlayStateStore.state.mode === authUpdateOverlayMode.SHOULD_UPDATE
                && authStore.getters.shouldUpdateAuths
                    ? "認証情報を更新せずにログアウトする"
                    : "閉じる"
        })

        watch(() => password1.value, () => {
            validatePW()
        })

        watch(() => password2.value, () => {
            validatePW()
        })

        const validatePW = () => {

            canClickUpdatePW.value = false
            pwWarningMessage.value = ""

            // 全角禁止
            // 英数を1文字以上ずつ含む

            if (password1.value.length > 0) {

                if (!new RegExp(/^[0-9a-zA-Z]*$/).test(password1.value)) {
                    pwWarningMessage.value = "パスワードに記号や全角文字は使えません。（半角英数字のみ）"
                } else if (!new RegExp(/(?=.*[a-zA-Z])(?=.*[0-9])/).test(password1.value)) {
                    pwWarningMessage.value = "パスワードは英字・数字を1文字以上ずつ含む必要があります。"
                } else if (password1.value.length < 8 || password1.value.length > 30) {
                    pwWarningMessage.value = "パスワードの長さは8文字以上、30文字以内としてください。"
                } else if (password2.value.length === 0) {
                    pwWarningMessage.value = "確認用パスワードを入力してください。"
                } else {
                    if (password1.value === password2.value) {
                        canClickUpdatePW.value = true
                    } else {
                        pwWarningMessage.value = "確認用パスワードが異なります。"
                    }
                }
            } else {
                pwWarningMessage.value = "パスワードが入力されていません。"
            }
        }

        const resetInputs = () => {
            password1.value = ""
            password2.value = ""

            canClickUpdatePW.value = false

            email.value = ""
            mailSentOnce.value = false

            nextTick(() => {
                pwWarningMessage.value = ""
            })
        }

        const hideOverlay = () => {
            authUpdateOverlayStateStore.commit("hideOverlay")
        }

        const router = useRouter()

        const onClickCancelUpdate = () => {

            // パスワード変更必須モードでキャンセルするとログアウトする。
            // またこの画面内で更新し、サーバに確認するとステータスが変化するので、ボタンの文言と挙動を変化させる。
            if (authUpdateOverlayStateStore.state.mode === authUpdateOverlayMode.SHOULD_UPDATE
                    && authStore.getters.shouldUpdateAuths) {
                authStore.commit("clearAuth")
                authStore.commit("clearToken")
                router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.NORMAL_LOGOUT.key
                    }
                })
            }

            hideOverlay()
        }

        const onClickUpdatePassword = async () => {

            const pw = password1.value

            password1.value = ""
            password2.value = ""
            await nextTick(() => {
                pwWarningMessage.value = ""
            })

            const resp = await backendApi.inquireUpdatePassword(authStore.state.auth.userId, pw)

            if (resp.isAuthorized) {
                if (resp.isSuccessful) {
                    toastStore.commit("post", {
                        message: "パスワードを更新しました"
                    })

                    if (authUpdateOverlayStateStore.state.mode === authUpdateOverlayMode.NORMAL_UPDATE) {
                        // 自発更新モードの時はCERT検証を走らせていないので、変更が成功した時点で目隠しを再表示する。
                        showPWFormOverlay.value = true
                    }

                } else {
                    toastStore.commit("post", {
                        message: "パスワード更新に失敗！"
                    })
                }
            } else {
                authStore.commit("clearAuth")
                authStore.commit("clearToken")
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.NORMAL_LOGOUT.key
                    }
                })
            }
        }

        const canClickSendMail = (): boolean => {
            return email.value.length > 0
        }

        const onClickSendVerificationMail = async () => {

            // メアドをポストした時点でサーバ側がnot_verifiedに切り替わるので、アプリ側もnot_verifiedに切り替え問合せを再開する。
            authStore.commit("updateEmailValidity", {
                mail: certMailStatuses.notVerified
            })

            mailSentOnce.value = true
            const resp = await backendApi.sendVerificationMail(authStore.state.auth.userId, email.value)
            if (resp.isAuthorized) {
                if (resp.isSuccessful) {
                    toastStore.commit("post", {
                        message: "確認メールを送信しました。"
                    })

                    if (authUpdateOverlayStateStore.state.mode === authUpdateOverlayMode.NORMAL_UPDATE) {
                        // 自発更新モードの時はページを表示してすぐに「更新の必要なし」と判断し、確認問合せをやめてしまう。
                        // メアドをポストした時点でサーバ側がnot_verifiedに切り替わるので、アプリ側もnot_verifiedに切り替え問合せを再開する。
                        // 実際に問合せを開始するのはメール送信に成功してから
                        startCheckingCertStatus()
                    }

                } else {
                    toastStore.commit("post", {
                        message: "メール送信失敗！"
                    })
                }
            } else {
                authStore.commit("clearAuth")
                authStore.commit("clearToken")
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.NORMAL_LOGOUT.key
                    }
                })
            }
        }

        let certCheckTimerId = 0

        const startCheckingCertStatus = () => {
            certCheckTimerId = window.setInterval(async () => {
                if (authStore.getters.shouldUpdateAuths) {
                    const resp = await backendApi.inquireCertStatus()
                    if (resp.isAuthorized) {
                        const auth = Auth.fromJson(resp.value)
                        console.log(auth)

                        if (authUpdateOverlayStateStore.state.mode === authUpdateOverlayMode.SHOULD_UPDATE) {
                            // 要更新状態でこのページを開いた、

                            if (auth.cert.password !== authStore.state.auth.cert.password) {
                                authStore.commit("updatePasswordValidity", {
                                    password: auth.cert.password
                                })
                            }

                            if (auth.cert.mail !== authStore.state.auth.cert.mail) {
                                authStore.commit("updateEmailValidity", {
                                    mail: auth.cert.mail
                                })
                            }

                            showPWFormOverlay.value = !authStore.getters.shouldUpdatePassword
                            showMailFormOverlay.value = !authStore.getters.shouldVerifyMail

                            if (!authStore.getters.shouldUpdateAuths) {
                                // 更新処理が成功してその必要がなくなった。
                                authUpdateOverlayStateStore.commit("hideOverlay")
                                toastStore.commit("post", {
                                    message: "認証情報の更新に成功しました。"
                                })
                            }

                        } else {
                            // 自発更新モードの時は問合せはメールに関するものだけを意識している。
                            if (auth.cert.mail !== authStore.state.auth.cert.mail) {
                                authStore.commit("updateEmailValidity", {
                                    mail: auth.cert.mail
                                })
                            }
                            showMailFormOverlay.value = !authStore.getters.shouldVerifyMail
                        }

                    }  else {
                        authStore.commit("clearAuth")
                        authStore.commit("clearToken")
                        await router.push({
                            path: subPaths.LOGGED_OUT,
                            query: {
                                logout_message: logoutMessages.NORMAL_LOGOUT.key
                            }
                        })
                    }
                } else {
                    stopCertCheck()
                }
            }, 1000)
        }

        const stopCertCheck = () => {
            window.clearInterval(certCheckTimerId)
        }

        const sendMailButtonText = (): string => {
            return mailSentOnce.value ? "確認メールを再送信" : "確認メールを送信"
        }

        authUpdateOverlayStateStore.watch(state => state.show, show => {
            if (show) {
                resetInputs()

                // 要更新モードの時は目隠しのない状態で表示されるし、自発更新モードの時は目隠しをして「する必要がない経度するのだ」という認識を与える。
                showPWFormOverlay.value = !authStore.getters.shouldUpdatePassword
                showMailFormOverlay.value = !authStore.getters.shouldVerifyMail

                console.log(showPWFormOverlay.value, showMailFormOverlay.value)

                startCheckingCertStatus()
            } else {
                stopCertCheck()
            }
        })

        const onClickReChangePW = () => {
            showPWFormOverlay.value = false
        }

        const onClickReVerifyMail = () => {
            showMailFormOverlay.value = false
        }

        return {
            authUpdateOverlayStateStore,
            FontSizes,
            authStore,
            password1,
            password2,
            pwWarningMessage,
            canClickUpdatePW,
            email,
            cancelText,
            canClickSendMail,
            onClickCancelUpdate,
            onClickUpdatePassword,
            onClickSendVerificationMail,
            sendMailButtonText,
            showPWFormOverlay,
            showMailFormOverlay,
            onClickReChangePW,
            onClickReVerifyMail,
        }
    }
})
