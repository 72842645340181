import {createStore} from "vuex";
import {Auth, certMailStatuses, certPasswordStatuses} from "@/data-models/auth";
import createPersistedState from "vuex-persistedstate";
import {differenceInHours} from "date-fns";

const shouldShowLogoutMessageLimitHours = 2

type authStoreType = {
    token: string,
    auth: Auth,
    lastUpdatedAt: Date,
}

export const authStore = createStore<authStoreType>({
    state: {
        token: "",
        auth: new Auth(),
        lastUpdatedAt: new Date(),
    },
    mutations: {
        updateToken (state, payload) {
            state.token = payload.token
            state.lastUpdatedAt = new Date()
        },
        clearToken (state) {
            state.token = ""
            state.lastUpdatedAt = new Date()
        },
        updateAuth (state, payload) {
            // state.auth = payload.auth
            // updateAuthExceptCertで発生したクラスインスタンスが単なるオブジェクトになってしまう問題はこちらでも起きているはず。顕現はしていないが。
            state.auth = new Auth()
            state.auth.replaceWithOther(payload.auth)
        },
        updateAuthExceptCert(state, payload) {
            // console.log(state.auth)
            // ページ再読み込み時にPersistedState（local storage）から再構築する際、クラスインスタンスではなく単なるObjectになってしまうので、クラスインスタンスを生成し、内容を書きなおす。
            if (state.auth.constructor.name !== "Auth") {
                const tmpAuth = state.auth
                state.auth = new Auth()
                state.auth.replaceWithOther(tmpAuth)
            }
            // console.log(state.auth.constructor.name)
            state.auth.replaceExceptCert(payload.auth)
        },
        clearAuth (state) {
            state.auth = new Auth()
        },
        updatePasswordValidity (state, payload) {
            state.auth.cert.password = payload.password
        },
        updateEmailValidity (state, payload) {
            state.auth.cert.mail = payload.mail
        },
    },
    getters: {
        existsToken (state): boolean {
            // console.log(state.token)
            return state.token.length > 0
        },
        authLevel (state): string {
            return state.auth.authLevel
        },
        shouldShowLoggedOut (state): boolean {
            return differenceInHours(state.lastUpdatedAt, new Date()) < shouldShowLogoutMessageLimitHours
        },
        shouldUpdatePassword (state): boolean {
            return state.auth.cert.password !== certPasswordStatuses.updated
        },
        shouldVerifyMail (state): boolean {
            return state.auth.cert.mail !== certMailStatuses.verified
        },
        shouldUpdateAuths (state, getters): boolean {
            return getters.shouldUpdatePassword || getters.shouldVerifyMail
        }
    },
    plugins: [createPersistedState()],
})