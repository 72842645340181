import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d44b2bf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container page-frame" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row justify-content-center mt-3" }
const _hoisted_4 = { class: "row justify-content-center my-2 button-wrap" }
const _hoisted_5 = { class: "row justify-content-center my-2 button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authStore.getters.authLevel !== _ctx.authLevels.DIST_STAFF)
      ? (_openBlock(), _createBlock(_component_Breadcrumbs, {
          key: 0,
          here: _ctx.staff.userName + 'さん',
          ancestries: _ctx.ancestries
        }, null, 8, ["here", "ancestries"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col h2",
        style: _normalizeStyle({ fontSize: _ctx.FontSizes.large2})
      }, _toDisplayString(_ctx.staff.userName) + "さんのページ", 5)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "col col-md-6 card",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickMoveToPrepayment && _ctx.onClickMoveToPrepayment(...args)))
      }, "前払い申し込み")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["col col-md-6 card", {'card-disabled': !_ctx.acceptApproval}]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickMoveToInvoiceOfMonth && _ctx.onClickMoveToInvoiceOfMonth(...args)))
      }, "月払い請求書発行", 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "col col-md-6 card",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickPostingDataDiff && _ctx.onClickPostingDataDiff(...args)))
      }, "配布記録一覧表示")
    ])
  ]))
}